<template>
    <div class="emoji-instructions" :class="[page === 0 ? 'more-margin' : '', from ? 'modalEnterCampaign' : '']">
        <div class="hr" />
        <div class="instruction" v-for="idx in instruction" :key="idx">
            <div class="left">
                <img :src="emojiSrc(idx)" />
            </div>
            <div class="right" v-if="from === 'modalEnterCampaign'" v-html="$translate(`CAMPAIGN_ENTER_${idx}`)" />
            <div class="right" v-else v-html="$translate(`CAMPAIGN_${page}_EMOJI_DESC_${idx}`)" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'OnsCampaignEmojiInstruction',
    props: {
        page: {
            type: Number,
            require: true,
        },
        from: String,
    },
    computed: {
        instruction() {
            if (this.page === 0) return 3
            else if (this.page === 4) return 2

            return 2
        },
    },
    methods: {
        emojiSrc(idx) {
            return require(`@/assets/images/my-page/GoodByeOnsCampaign/ons_campaign_${this.page}_img_${idx}.png`)
        },
    },
}
</script>

<style scoped lang="scss">
.emoji-instructions {
    &.more-margin {
        margin-bottom: 100px;
    }
    &.modalEnterCampaign {
        margin-bottom: 44px !important;

        .instruction {
            margin-bottom: 16px;
        }
    }
}
.hr {
    width: 20px;
    margin: 0 auto 28px auto;
    border-top: 4px solid rgba($blue-facebook, 0.1);
}
.instruction {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    padding-left: 40px;

    .left {
        flex: none;
        width: 90px;
        height: 90px;

        img {
            width: 100%;
            height: 100%;
        }
    }
    .right {
        flex: auto;
        padding-left: 16px;
        color: black;
    }
}
</style>
